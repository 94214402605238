/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/prefer-default-export */

// https://www.graphql-code-generator.com/docs/plugins/typescript-react-query#usage-example-isreacthook-true

export const useFetchData = <TData, TVariables>(
  query: string
): (() => Promise<TData>) => {
  return async (variables?: TVariables) => {
    const tokenResponse = await fetch('/api/auth/token');

    const { accessToken } = (await tokenResponse.json()) as {
      accessToken: string;
    };

    const url = process.env.NEXT_PUBLIC_ARC_GATEWAY_URL || '';
    if (!url) {
      throw new Error('NEXT_PUBLIC_ARC_GATEWAY_URL not set');
    }
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0] || 'Error';
      throw new Error(message as string);
    }

    return json.data;
  };
};
