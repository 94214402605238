const ReorderAssistantIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 23.25V21.75C0.75 21.3522 0.908035 20.9706 1.18934 20.6893C1.47064 20.408 1.85218 20.25 2.25 20.25C2.64782 20.25 3.02936 20.408 3.31066 20.6893C3.59196 20.9706 3.75 21.3522 3.75 21.75V23.25"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 20.25V15.75C21.75 15.3522 21.592 14.9706 21.3107 14.6893C21.0294 14.408 20.6478 14.25 20.25 14.25H3.75C3.35218 14.25 2.97064 14.408 2.68934 14.6893C2.40804 14.9706 2.25 15.3522 2.25 15.75V20.25"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.25 23.25V21.75C23.25 21.3522 23.092 20.9706 22.8107 20.6893C22.5294 20.408 22.1478 20.25 21.75 20.25C21.3522 20.25 20.9706 20.408 20.6893 20.6893C20.408 20.9706 20.25 21.3522 20.25 21.75V23.25"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 19.026C18.4559 18.7621 18.8345 18.3829 19.0978 17.9266C19.361 17.4703 19.4997 16.9528 19.5 16.426V11.25C19.5 9.26088 18.7098 7.35322 17.3033 5.9467C15.8968 4.54018 13.9891 3.75 12 3.75C10.0109 3.75 8.10322 4.54018 6.6967 5.9467C5.29018 7.35322 4.5 9.26088 4.5 11.25V16.426C4.49966 16.9529 4.63812 17.4707 4.90144 17.9271C5.16477 18.3835 5.54366 18.7625 6 19.026C7.82456 20.0782 9.89376 20.6322 12 20.6322C14.1062 20.6322 16.1754 20.0782 18 19.026Z"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9V10.5"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9V10.5"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3.75V0.75"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReorderAssistantIcon;
