/* eslint-disable react/no-unused-prop-types */
/* ☝️ this rule is new, and has a known issue with stateless function components returning JSX expressions
   triggering false positives. It fails at OrgUserMenuItem, possibly because those props are used in
   the menu overlay outside of the render. https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md
   for more info.
*/

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import type { AvatarSize } from 'antd/lib/avatar/SizeContext';
import type { ReactElement } from 'react';

export interface OrgUserMenuItem {
  key: string;
  onClick: () => void;
  value: string;
  icon: ReactElement;
}

export interface OrgUserMenuProps {
  avatarSrc?: string | null;
  avatarSize?: AvatarSize;
  name?: string | null;
  email?: string | null;
  items: OrgUserMenuItem[];
}

const OrgUserMenu = ({
  avatarSrc,
  avatarSize,
  name,
  items,
  email,
}: OrgUserMenuProps) => {
  const menu = (
    <Menu>
      {items?.map(({ key, onClick, value, icon }: OrgUserMenuItem) => (
        <Menu.Item key={key} onClick={onClick}>
          <Space>
            {icon}
            {value}
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className="w-full">
        <Space align="center">
          <div>
            {avatarSrc && <Avatar src={avatarSrc} size={avatarSize} />}
            {!avatarSrc && <Avatar icon={<UserOutlined />} size={avatarSize} />}
          </div>
          <div className="flex flex-col ml-2">
            {name && (
              <span className="text-blue-700 font-semibold text-md">
                {name}
              </span>
            )}
            {email && (
              <span className="text-gray-700 font-light text-sm">{email}</span>
            )}
          </div>
          <div>
            <DownOutlined />
          </div>
        </Space>
      </div>
    </Dropdown>
  );
};

export default OrgUserMenu;
