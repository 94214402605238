import { useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useGetLicensesQuery } from '@/graphql/hooks';
import { License, LicenseFilter } from '@/graphql/types';

export const useOrgValidation = () => {
  const {
    query: { org: organizationKey },
  } = useRouter();
  const [userHasPermission, setUserHasPermission] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { user, isLoading: userLoading } = useUser();

  const orgKey = organizationKey as string;

  useEffect(() => {
    if (!userLoading && user) {
      // MVP - Temporary permission settings. Should be moved t0 Arc-Gateway / Tenant
      // the original `read:reup:organization:*` is tied to an Arc Gateway product lookup. Need a new
      // permission to determine administrators until Arc is updated
      const adminPermission = 'read:reup:admin:*';
      const orgPermission = `read:reup:organization:${orgKey}`;

      const permissions = user
        ? (user['https://growflow.com/permissions'] as string[])
        : [''];

      if (permissions && permissions.length > 0) {
        if (permissions.includes(adminPermission)) {
          setUserHasPermission(true);
        } else {
          setUserHasPermission(permissions.includes(orgPermission));
        }
      }
      setIsLoaded(true);
    }
  }, [orgKey, user, userLoading]);

  return {
    orgExists: true,
    userHasPermission,
    isLoaded,
    user,
  };
};

export const useGetBuyerLicenseByLicenseNumber = (licenseNumber: string) => {
  const [license, setLicense] = useState({} as License);

  const licenseFilter: LicenseFilter = {
    licenseNumber: {
      equalTo: licenseNumber,
    },
  };

  const { data: licenseData, isFetched: licensesFetched } = useGetLicensesQuery(
    { filter: licenseFilter },
    {
      enabled: licenseNumber.length > 0,
    }
  );

  useEffect(() => {
    if (
      licensesFetched &&
      licenseData?.licenses &&
      licenseData?.licenses?.nodes?.length > 0
    ) {
      setLicense(licenseData?.licenses?.nodes[0] as License);
    }
  }, [licensesFetched, licenseData?.licenses, license]);

  return { license, licensesFetched };
};
