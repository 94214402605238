import { PoweroffOutlined } from '@ant-design/icons';
import { useUser } from '@auth0/nextjs-auth0';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import OrgUserMenu from './OrgUserMenu';

type LeftNavProps = {
  orgKey: string;
  storeKey: string;
};

const TopNav: FC<LeftNavProps> = ({ orgKey, storeKey }) => {
  const [pageTitle, setPageTitle] = useState('');

  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.includes('purchase-orders/drafts')) {
      setPageTitle('Draft Orders');
    } else if (router.asPath.includes('reorder-assistant')) {
      setPageTitle('Reorder Assistant');
    } else if (router.asPath.includes('placed')) {
      setPageTitle('Placed Orders');
    } else {
      setPageTitle('Dashboard');
    }
  }, [router.asPath]);

  const logout = () => {
    void router.push('/api/auth/logout');
  };

  return (
    <div className="flex flex-row px-5 py-2 justify-between items-center border border-solid border-gray-300 border-b-1 bg-gray-100 max-h-16">
      <Link href={`/${orgKey}?store=${storeKey}`} passHref>
        <Image
          alt="GrowFlow"
          src="https://cdn.growflow.com/img/v2/logos/growflow-main-logo.svg"
          height="32"
          width="132"
        />
      </Link>
      <h3 className="items-center">{pageTitle}</h3>
      <div className="h-12 items-center">
        <OrgUserMenu
          name={user?.name}
          email={user?.email}
          avatarSrc={user?.picture}
          avatarSize={42}
          items={[
            {
              key: 'logout',
              value: 'Logout',
              icon: <PoweroffOutlined />,
              onClick: () => logout(),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TopNav;
