import { useUser } from '@auth0/nextjs-auth0';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useOrgValidation } from '@/lib/hooks';

import LayoutContent from './LayoutContent';
import LeftNav from './LeftNav';
import TopNav from './TopNav';

const LayoutNavContainer = ({ children }: { children: React.ReactNode }) => {
  const {
    push,
    pathname,
    isReady,
    query: { org, store },
  } = useRouter();

  const { user } = useUser();

  Sentry.configureScope((_scope: Sentry.Scope) => {
    _scope.setUser({
      email: user?.email as string,
    });
    _scope.setExtra('organization', org);
    _scope.setExtra('store', store);
  });

  const { userHasPermission, isLoaded } = useOrgValidation();

  // MVP - Evaluating Reup permissions here for all pages.
  useEffect(() => {
    if (
      isReady &&
      !pathname.includes('403') &&
      !userHasPermission &&
      isLoaded
    ) {
      void push(`/${org as string}/403`);
    }
  }, [isLoaded, isReady, org, pathname, push, userHasPermission]);

  return (
    <div>
      <TopNav orgKey={org as string} storeKey={store as string} />
      <div className="flex items-stretch min-h-screen">
        <LeftNav orgKey={org as string} storeKey={store as string} />
        <LayoutContent>{children}</LayoutContent>
      </div>
    </div>
  );
};

export default LayoutNavContainer;
