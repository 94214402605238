import '@/theme/globals.less';
import '@/theme/tailwind.css';
import '@/theme/antdoverrides.css';

import { UserProvider } from '@auth0/nextjs-auth0';
import type { AppProps } from 'next/app';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { getAnalyticsSnippet } from '@/analytics';

import { AppContextProvider } from '../context/AppContext';

const queryClient = new QueryClient({});

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <Component {...pageProps} />
        </AppContextProvider>
      </QueryClientProvider>
    </UserProvider>
    {/* eslint-disable-next-line react/no-danger */}
    <script dangerouslySetInnerHTML={{ __html: getAnalyticsSnippet() }} />
  </>
);

export default MyApp;
