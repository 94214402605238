const PurchaseOrdersIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="purchase-orders">
      <g id="Group">
        <path
          id="Vector"
          d="M13.7325 21.75H4.25C3.91848 21.75 3.60054 21.6183 3.36612 21.3839C3.1317 21.1495 3 20.8315 3 20.5V4.25C3 3.91848 3.1317 3.60054 3.36612 3.36612C3.60054 3.1317 3.91848 3 4.25 3H20.5C20.8315 3 21.1495 3.1317 21.3839 3.36612C21.6183 3.60054 21.75 3.91848 21.75 4.25V13.7325C21.7499 14.0638 21.6183 14.3815 21.3842 14.6158L14.6158 21.3842C14.3815 21.6183 14.0638 21.7499 13.7325 21.75V21.75Z"
          stroke="#030047"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14.25 21.6375V15.5C14.25 15.1685 14.3817 14.8505 14.6161 14.6161C14.8505 14.3817 15.1685 14.25 15.5 14.25H21.6375"
          stroke="#030047"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="Group 24">
        <path
          id="Vector_3"
          d="M13.644 5.89502L11.769 12.145H6.76904L5.51904 8.39502H12.894"
          stroke="#030047"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M7.08154 14.02C7.14335 14.02 7.20377 14.0383 7.25516 14.0727C7.30655 14.107 7.3466 14.1558 7.37026 14.2129C7.39391 14.27 7.4001 14.3329 7.38804 14.3935C7.37598 14.4541 7.34622 14.5098 7.30252 14.5535C7.25881 14.5972 7.20313 14.627 7.14251 14.639C7.08189 14.6511 7.01906 14.6449 6.96196 14.6212C6.90485 14.5976 6.85605 14.5575 6.82171 14.5061C6.78737 14.4547 6.76904 14.3943 6.76904 14.3325C6.76893 14.2915 6.77694 14.2508 6.79261 14.2128C6.80827 14.1748 6.83129 14.1403 6.86033 14.1113C6.88937 14.0823 6.92386 14.0593 6.96183 14.0436C6.99979 14.0279 7.04048 14.0199 7.08154 14.02"
          stroke="#030047"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M11.4565 14.02C11.5184 14.02 11.5788 14.0383 11.6302 14.0727C11.6816 14.107 11.7216 14.1558 11.7453 14.2129C11.7689 14.27 11.7751 14.3329 11.763 14.3935C11.751 14.4541 11.7212 14.5098 11.6775 14.5535C11.6338 14.5972 11.5781 14.627 11.5175 14.639C11.4569 14.6511 11.3941 14.6449 11.337 14.6212C11.2799 14.5976 11.231 14.5575 11.1967 14.5061C11.1624 14.4547 11.144 14.3943 11.144 14.3325C11.1439 14.2915 11.1519 14.2508 11.1676 14.2128C11.1833 14.1748 11.2063 14.1403 11.2353 14.1113C11.2644 14.0823 11.2989 14.0593 11.3368 14.0436C11.3748 14.0279 11.4155 14.0199 11.4565 14.02"
          stroke="#030047"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default PurchaseOrdersIcon;
