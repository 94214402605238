import * as snippet from '@segment/snippet';

import { Context, License as SegmentLicense } from './segment';

const env = process.env.NEXT_PUBLIC_BUILD_ENVIRONMENT;
const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;

export type Product = 'Reup';
type EventData<T> = T & {
  product: Product;
  license: SegmentLicense;
  context: Context;
};

type IdentifyData = {
  CurrentAccountLink: string;
  license: SegmentLicense;
  Email: string;
  Name: string;
  Product: Product;
}

export const getAnalyticsSnippet = (): string => {  
  const opts = { apiKey: segmentKey, page: false };
  return env === 'production' ? snippet.min(opts) : snippet.max(opts);  
};

export const generateEventData = <T>(
  clientId: string,
  storeName: string,
  storeLicenseNumber: string,
  storeLicenseState: string,
  payload: T
): EventData<T> => {
  const license: SegmentLicense = {
    licenseName: storeName,
    licenseGroup: 'Retail',
    licenseNumber: storeLicenseNumber,
    regionAbbreviation: storeLicenseState,
  };

  return {
    product: 'Reup',
    license,
    context: {
      groupId: clientId,
    },
    ...payload,
  };
};

export const identify = (data: IdentifyData) => {
  if(window.analytics){
    window.analytics.identify({
      Current_Account_Link: data.CurrentAccountLink,
      License_Name: data.license.licenseName,
      License_Num: data.license.licenseNumber,
      Product: data.Product,
      email: data.Email,
      name: data.Name
    })
  }
  
}



// "Current_Account_Link": {
//   "description": "The fully qualified URL that a CH rep would use if they wanted to login directly to the License/Environment that the user is currently utilizing.",
//   "id": "/properties/traits/properties/Current_Account_Link",
//   "pattern": "@(https?|ftp)://(-\\.)?([^\\s/?\\.#-]+\\.?)+(/[^\\s]*)?$@iS",
//   "type": "string"
// },
// "License_Name": {
//   "description": "The Name on the currently selected/active License",
//   "id": "/properties/traits/properties/License_Name",
//   "type": "string"
// },
// "License_Num": {
//   "description": "The currently selected/active License #",
//   "id": "/properties/traits/properties/License_Num",
//   "type": "string"
// },
// "Product": {
//   "description": "The Product that the user is currently using (i.e. being \"identified\" from)",
//   "enum": [
//     "Retail",
//     "External",
//     "Reporting",
//     "StoreFront",
//     "Wholesale",
//     "ReUp"
//   ],
//   "id": "/properties/traits/properties/Product",
//   "type": "string"
// },
// "email": {
//   "description": "User's primary email used with GrowFlow Applications",
//   "id": "/properties/traits/properties/email",
//   "pattern": "\t\n(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
//   "type": "string"
// },
// "name": {
//   "description": "User's full name",
//   "id": "/properties/traits/properties/name",
//   "type": "string"
// }
