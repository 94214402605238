/* eslint-disable jsx-a11y/anchor-is-valid */
import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import {
  DashboardIcon,
  HelpIcon,
  PurchaseOrdersIcon,
  ReorderAssistantIcon,
} from '@/icons';

type LeftNavProps = {
  orgKey: string;
  storeKey: string;
};

const LeftNav: FC<LeftNavProps> = ({ orgKey, storeKey }) => {
  const { pathname } = useRouter();
  let selectedKeys = ['nav-dashboard'];

  if (pathname.includes('reorder-assistant'))
    selectedKeys = ['nav-reorder-assistant'];

  if (pathname.includes('purchase-orders'))
    selectedKeys = ['nav-purchase-order'];

  return (
    <div className="flex flex-col justify-between border-0 border-r border-solid border-gray-300 bg-gray-100 pt-6">
      <Menu
        mode="inline"
        theme="light"
        inlineCollapsed
        selectedKeys={selectedKeys}
      >
        <Menu.Item
          key="nav-dashboard"
          icon={<Icon component={DashboardIcon} />}
          title="Dashboard"
        >
          <Link href={`/${orgKey}?store=${storeKey}`}>
            <a>Dashboard</a>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="nav-reorder-assistant"
          icon={<Icon component={ReorderAssistantIcon} />}
          title="Reorder Assistant"
        >
          <Link href={`/${orgKey}/reorder-assistant?store=${storeKey}`}>
            <a>Reorder Assistant</a>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="nav-purchase-order"
          icon={<Icon component={PurchaseOrdersIcon} />}
          title="Purchase Orders"
        >
          <Link href={`/${orgKey}/purchase-orders/drafts?store=${storeKey}`}>
            <a>Purchase Orders</a>
          </Link>
        </Menu.Item>
      </Menu>
      <Menu mode="inline" theme="light" inlineCollapsed>
        <Menu.Item
          key="nav-nav-help"
          icon={<Icon component={HelpIcon} />}
          title="Help"
        >
          <Link href="https://help.growflow.com">
            <a target="_blank">Help</a>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default LeftNav;
