import React, { useMemo, useState } from 'react';

import LayoutNavContainer from '@/components/common/LayoutNavContainer';

import { PurchaseOrder, UiProduct } from '../types';

type AppDataType = {
  loading: boolean;
  setLoading: (isLoading: boolean) => void;
  products: UiProduct[];
  setProducts: (reorderValue: UiProduct[]) => void;
  purchaseOrders: PurchaseOrder[];
  setPurchaseOrders: (value: PurchaseOrder[]) => void;
};

const AppData = React.createContext<AppDataType>(undefined!);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // state
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([] as UiProduct[]);
  const [purchaseOrders, setPurchaseOrders] = useState([] as PurchaseOrder[]);

  // memoize the full context value to eliminate unnecessary rendering
  const contextValue = useMemo(
    () => ({
      loading,
      setLoading: (isLoading: boolean) => setLoading(isLoading),
      products,
      setProducts: (value: UiProduct[]) => setProducts(value),
      purchaseOrders,
      setPurchaseOrders: (value: PurchaseOrder[]) => setPurchaseOrders(value),
    }),
    [
      loading,
      setLoading,
      products,
      setProducts,
      purchaseOrders,
      setPurchaseOrders,
    ]
  );

  return (
    <LayoutNavContainer>
      <AppData.Provider value={contextValue}>{children}</AppData.Provider>
    </LayoutNavContainer>
  );
};

export const useAppData = () => React.useContext(AppData);
