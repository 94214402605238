const DashboardIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="dashboard">
      <path
        id="Vector"
        d="M8.2998 13.9565V13.7C8.2998 12.9839 8.58427 12.2972 9.09062 11.7908C9.59696 11.2845 10.2837 11 10.9998 11C11.7159 11 12.4026 11.2845 12.909 11.7908C13.4153 12.2972 13.6998 12.9839 13.6998 13.7V13.9565"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M13.027 4.92505L11.002 11"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M5.93906 6.94995C5.87231 6.94995 5.80706 6.96975 5.75156 7.00683C5.69606 7.04392 5.6528 7.09663 5.62725 7.1583C5.60171 7.21997 5.59503 7.28783 5.60805 7.35329C5.62107 7.41876 5.65321 7.4789 5.70041 7.5261C5.74761 7.5733 5.80775 7.60544 5.87322 7.61847C5.93869 7.63149 6.00655 7.6248 6.06822 7.59926C6.12989 7.57372 6.1826 7.53046 6.21968 7.47496C6.25677 7.41945 6.27656 7.3542 6.27656 7.28745C6.27656 7.19794 6.241 7.1121 6.17771 7.0488C6.11442 6.98551 6.02857 6.94995 5.93906 6.94995Z"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M17.4142 10.325C17.3474 10.325 17.2822 10.3447 17.2267 10.3818C17.1712 10.4189 17.1279 10.4716 17.1024 10.5333C17.0768 10.595 17.0701 10.6628 17.0831 10.7283C17.0962 10.7938 17.1283 10.8539 17.1755 10.9011C17.2227 10.9483 17.2828 10.9804 17.3483 10.9935C17.4138 11.0065 17.4816 10.9998 17.5433 10.9743C17.605 10.9487 17.6577 10.9055 17.6948 10.85C17.7319 10.7945 17.7517 10.7292 17.7517 10.6625C17.7517 10.5729 17.7161 10.4871 17.6528 10.4238C17.5895 10.3605 17.5037 10.325 17.4142 10.325V10.325Z"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_5"
        d="M16.0644 6.94996C15.9976 6.9496 15.9321 6.96909 15.8764 7.00596C15.8207 7.04283 15.7771 7.09541 15.7513 7.15705C15.7255 7.21869 15.7185 7.2866 15.7314 7.35219C15.7442 7.41778 15.7762 7.47808 15.8233 7.52546C15.8704 7.57284 15.9306 7.60516 15.9961 7.61833C16.0616 7.6315 16.1296 7.62493 16.1913 7.59943C16.2531 7.57394 16.3059 7.53069 16.3431 7.47515C16.3803 7.41961 16.4001 7.35428 16.4001 7.28746C16.4001 7.19795 16.3645 7.1121 16.3012 7.04881C16.238 6.98551 16.1521 6.94996 16.0626 6.94996"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_6"
        d="M8.63926 4.25C8.57251 4.25 8.50725 4.26979 8.45175 4.30688C8.39625 4.34396 8.35299 4.39667 8.32745 4.45834C8.3019 4.52001 8.29522 4.58787 8.30824 4.65334C8.32127 4.71881 8.35341 4.77895 8.40061 4.82615C8.44781 4.87335 8.50795 4.90549 8.57342 4.91852C8.63888 4.93154 8.70674 4.92485 8.76841 4.89931C8.83008 4.87376 8.88279 4.83051 8.91988 4.775C8.95696 4.7195 8.97676 4.65425 8.97676 4.5875C8.97676 4.49799 8.9412 4.41215 8.87791 4.34885C8.81461 4.28556 8.72877 4.25 8.63926 4.25V4.25Z"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_7"
        d="M19.4875 16.5215C17.0368 14.6897 14.0593 13.7 10.9996 13.7C7.93999 13.7 4.96245 14.6897 2.51172 16.5215"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_8"
        d="M0.875 11C0.875 13.6853 1.94174 16.2607 3.84054 18.1595C5.73935 20.0583 8.31468 21.125 11 21.125C13.6853 21.125 16.2607 20.0583 18.1595 18.1595C20.0583 16.2607 21.125 13.6853 21.125 11C21.125 8.31468 20.0583 5.73935 18.1595 3.84054C16.2607 1.94174 13.6853 0.875 11 0.875C8.31468 0.875 5.73935 1.94174 3.84054 3.84054C1.94174 5.73935 0.875 8.31468 0.875 11V11Z"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_9"
        d="M4.58945 10.325C4.5227 10.325 4.45745 10.3447 4.40195 10.3818C4.34645 10.4189 4.30319 10.4716 4.27764 10.5333C4.2521 10.595 4.24542 10.6628 4.25844 10.7283C4.27146 10.7938 4.3036 10.8539 4.3508 10.9011C4.39801 10.9483 4.45814 10.9804 4.52361 10.9935C4.58908 11.0065 4.65694 10.9998 4.71861 10.9743C4.78028 10.9487 4.83299 10.9055 4.87007 10.85C4.90716 10.7945 4.92695 10.7292 4.92695 10.6625C4.92695 10.5729 4.8914 10.4871 4.8281 10.4238C4.76481 10.3605 4.67896 10.325 4.58945 10.325Z"
        stroke="#030047"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default DashboardIcon;
