const HelpIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 11C7.25 11.9946 7.64509 12.9484 8.34835 13.6517C9.05161 14.3549 10.0054 14.75 11 14.75C11.9946 14.75 12.9484 14.3549 13.6517 13.6517C14.3549 12.9484 14.75 11.9946 14.75 11C14.75 10.0054 14.3549 9.05161 13.6517 8.34835C12.9484 7.64509 11.9946 7.25 11 7.25C10.0054 7.25 9.05161 7.64509 8.34835 8.34835C7.64509 9.05161 7.25 10.0054 7.25 11Z"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1188 19.3009L11.5288 14.7109"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7109 11.53L19.3009 16.119"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.69922 16.1191L7.28922 11.5291"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4709 14.7119L5.88086 19.3009"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.88086 2.69995L10.4709 7.28895"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.28922 10.4709L2.69922 5.88086"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3009 5.8811L14.7109 10.4711"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5288 7.28895L16.1188 2.69995"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 11C1.25 13.5859 2.27723 16.0658 4.10571 17.8943C5.93419 19.7228 8.41414 20.75 11 20.75C13.5859 20.75 16.0658 19.7228 17.8943 17.8943C19.7228 16.0658 20.75 13.5859 20.75 11C20.75 8.41414 19.7228 5.93419 17.8943 4.10571C16.0658 2.27723 13.5859 1.25 11 1.25C8.41414 1.25 5.93419 2.27723 4.10571 4.10571C2.27723 5.93419 1.25 8.41414 1.25 11V11Z"
      stroke="#030047"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;
